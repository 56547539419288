import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import SecuritySection from "../components/static-sections/securitySection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"

/** svg */
import SecurityIcon from "../../assets/security-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const SecurityPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/legal/security/",
          name: "Security | Norhart",
          image: `${config.siteMetadata.siteUrl}/security/norhart-security-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Security | Norhart"
      description="At Norhart, our mission is to make sure your data is safe and secure all the time."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/security/norhart-security-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/security/norhart-security-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Security Statement"
        subtitle="We Totally Protect Your Data"
        imageTitle="Norhart Security"
        image="/security/norhart-security-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection title="Norhart Security" tagLine="We protect your private data" colorPalette={colorPalette} />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <TwoImageSection
        backgroundColorOne="#E5E8e9"
        backgroundColorTwo="#EFF2F4"
        fontTextColor="#365c70"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Security"
        title="Security You Can Trust"
        subtitle="At Norhart, our mission is to make sure your data is safe and secure all the time. When possible, we like to allow the verification and validation of our security measures to be completely transparent."
        imageTitleOne="Privacy a fundamental right"
        imageOneText="We value the trust and confidence you put in us to protect and secure your sensitive data. We take this responsibility very seriously. Our number one job is to make sure your data is always safe and secure. When possible, we like to allow the verification and validation of our security measures."
        imageOne="/security/norhart-hardened-security.webp"
        imageOneAlt="Norhart Hardened Security"
        imageOnePill=""
        imageTitleTwo="AES 256-bit encryption"
        imageTwoText="Norhart uses AES 256-bit encryption and TLS 1.3 to protect your data. The same level of encryption is used by the National Security Agency (NSA) to protect information at a “Top Secret” level. AES 256-bit is now widely-accepted as the strongest encryption there is."
        imageTwo="/security/norhart-security-lingo.webp"
        imageTwoAlt="Norhart Security Protections"
        imageTwoPill=""
        pillActive={false}
        colorPalette={colorPalette}
      />

      <SecuritySection image="/security/analytic-wave.png" imageAlt="Norhart Transparent Security And Privacy" />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Uptime Reports"
        badgeTitle="Reporting"
        title="Real-Time Status"
        subtitle="Experiencing some sort of issue? Take a look at our site availability reports."
        imageTitle="Norhart Website Pingdom Uptime Report"
        image="/security/norhart-security-pingdom-uptime-report.png"
        buttonName="Take a Peek"
        buttonUrl="http://stats.pingdom.com/i6owumclg489"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade"
      />
    </Layout>
  )
}

/** export */
/** export */
export default SecurityPage
