import React from "react"

/** svg */
import CompareIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  image: string
  imageAlt: string
}

/** const */
/** const */
const securitySection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-0"
      style={{
        background: "linear-gradient(#cfe9f9, #cfe9f9)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3 mt-5 mb-0 pb-0">
        <div>
          <div className="text-center pb-1 text-center">
            <CompareIcon />
          </div>
        </div>

        <div className="contentHeroSpace hidden-xs"></div>
        <h2 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Hardened Security You Can Trust
        </h2>
        <h2 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.7" }}>
          The security and privacy of your data is our number one priority! We encourage you to verify our security and
          privacy practices using the links below.
        </h2>

        <div className="container">
          <div class="row align-items-start">
            <div className="col 2 chartTitle">Name</div>
            <div className="col 6 chartTitle d-none d-sm-block">Description</div>
            <div className="col 2 chartTitle text-end">Score</div>
            <div className="col 2 chartTitle text-end">Link</div>
          </div>

          <hr />

          <div class="row align-items-start mb-3">
            <div className="col 2 chartSubTitle">Website</div>
            <div className="col 6 chartSubTitle d-none d-sm-block">www.norhart.com</div>
            <div className="col 2 chartSubTitle text-end">
              <a href="https://securityheaders.com/?q=https%3A%2F%2Fwww.norhart.com&followRedirects=on" target="_blank">
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  A+
                </span>
              </a>
            </div>
            <div className="col 2 chartSubTitle text-end">
              <a href="https://securityheaders.com/?q=https%3A%2F%2Fwww.norhart.com&followRedirects=on" target="_blank">
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  Verify
                </span>
              </a>
            </div>
          </div>

          <div class="row align-items-start mb-3">
            <div className="col 2 chartSubTitle">Blog</div>
            <div className="col 6 chartSubTitle d-none d-sm-block">www.norhart.com/blog/</div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Ffeedback.norhart.com&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  A+
                </span>
              </a>
            </div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Fwww.norhart.com%2Fblog%2F&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  Verify
                </span>
              </a>
            </div>
          </div>

          <div class="row align-items-start mb-3">
            <div className="col 2 chartSubTitle">Feedback</div>
            <div className="col 6 chartSubTitle d-none d-sm-block">feedback.norhart.com</div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Ffeedback.norhart.com&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  A+
                </span>
              </a>
            </div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Ffeedback.norhart.com&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  Verify
                </span>
              </a>
            </div>
          </div>

          <div class="row align-items-start mb-3">
            <div className="col 2 chartSubTitle">Invest</div>
            <div className="col 6 chartSubTitle d-none d-sm-block">invest.norhart.com</div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Ffeedback.norhart.com&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  A+
                </span>
              </a>
            </div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Finvest.norhart.com&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  Verify
                </span>
              </a>
            </div>
          </div>

          <div class="row align-items-start mb-3">
            <div className="col 2 chartSubTitle">Store</div>
            <div className="col 6 chartSubTitle d-none d-sm-block">store.norhart.com</div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Fstore.norhart.com&followRedirects=on"
                target="_blank"
              >
                <span
                  className="btn btn-primary chartLink"
                  style={{ backgroundColor: "#27cf4b", border: "#27cf4b", width: "50px" }}
                >
                  A
                </span>
              </a>
            </div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Fstore.norhart.com&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#27cf4b", border: "#27cf4b" }}>
                  Verify
                </span>
              </a>
            </div>
          </div>

          <div class="row align-items-start mb-3">
            <div className="col 2 chartSubTitle">Apply</div>
            <div className="col 6 chartSubTitle d-none d-sm-block">rentmanager.com/applynow</div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Fnorhart.twa.rentmanager.com%2Fapplynow&followRedirects=on"
                target="_blank"
              >
                <span
                  className="btn btn-primary chartLink"
                  style={{ backgroundColor: "#c9352c", border: "#c9352c", width: "50px" }}
                >
                  F
                </span>
              </a>
            </div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Fnorhart.twa.rentmanager.com%2Fapplynow&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#c9352c", border: "#c9352c" }}>
                  Verify
                </span>
              </a>
            </div>
          </div>

          <div class="row align-items-start mb-3">
            <div className="col 2 chartSubTitle">My Account</div>
            <div className="col 6 chartSubTitle d-none d-sm-block">rentmanager.com</div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Fnorhart.twa.rentmanager.com&followRedirects=on"
                target="_blank"
              >
                <span
                  className="btn btn-primary chartLink"
                  style={{ backgroundColor: "#c9352c", border: "#c9352c", width: "50px" }}
                >
                  F
                </span>
              </a>
            </div>
            <div className="col 2 chartSubTitle text-end">
              <a
                href="https://securityheaders.com/?q=https%3A%2F%2Fnorhart.twa.rentmanager.com&followRedirects=on"
                target="_blank"
              >
                <span className="btn btn-primary chartLink" style={{ backgroundColor: "#c9352c", border: "#c9352c" }}>
                  Verify
                </span>
              </a>
            </div>
          </div>

          <div class="row">
            <img
              className="img-fluid z-depth-1 rounded"
              src={props.image}
              loading="lazy"
              decoding="async"
              alt={props.imageAlt}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default securitySection
